<template>
  <div v-if="authenticated !== undefined">
    <AccountHeader v-if="authenticated" :nav="nav" :active="active" />
    <div v-else class="sidebar-horizontal">
      <nav class="navbar navbar-expand-md bg-blue-gradient">
        <div class="container">
          <div class="nav-header">
            <router-link to="/" class="navbar-brand"><p>{{ $config.name }}</p></router-link>
          </div>

          <ul v-if="nav" class="nav navbar-nav">
            <li class="sidebar-toggle dropdown"><a href="javascript:void(0)" @click="toggleNav" class="ripple px-2 mx-2"><i class="fal fa-bars list-icon fs-20"></i></a></li>
          </ul>

          <div class="collapse navbar-collapse">
            <ul class="navbar-nav ml-auto">
              <li class="nav-item"><router-link class="nav-link" to="/contact"><i class="fal fa-envelope mr-2" aria-hidden="true"></i>hello@agiler.io</router-link></li>
              <li class="nav-item"><a class="nav-link" href="tel:1-800-249-1897"><i class="fal fa-phone mr-2" aria-hidden="true"></i>(800) 249-1897</a></li>
            </ul>
          </div>

          <ul class="navbar-nav ml-auto ml-md-0">
            <li class="nav-item d-md-none"><router-link class="nav-link" to="/contact"><i class="fal fa-envelope mr-2" aria-hidden="true"></i></router-link></li>
            <li class="nav-item d-md-none"><a class="nav-link" href="tel:1-800-249-1897"><i class="fal fa-phone mr-2" aria-hidden="true"></i></a></li>
            <li class="nav-item"><router-link to="/login" class="btn btn-outline-light btn-rounded btn-sm px-4 py-2 ml-2">Log In<i class="fal fa-sign-in ml-2"></i></router-link></li>
          </ul>
        </div>
      </nav>

      <div v-if="nav === 'help'" class="content-wrapper">
        <aside class="site-sidebar clearfix rounded" :class="{ 'd-block': showNav }">
          <nav class="sidebar-nav">
            <div class="container">
              <ul class="nav in side-menu">
                <li :class="{ active: active === 'termsofservice' }"><router-link to="/help/termsofservice"><i class="list-icon fal fa-fw fa-file-contract"></i>Terms of Service</router-link></li>
                <li :class="{ active: active === 'privacypolicy' }"><router-link to="/help/privacypolicy"><i class="list-icon fal fa-fw fa-file-contract"></i>Privacy Policy</router-link></li>
              </ul>
            </div>
          </nav>
        </aside>
      </div>

      <div v-if="nav === 'blog'" class="content-wrapper">
        <aside class="site-sidebar clearfix rounded" :class="{ 'd-block': showNav }">
          <nav class="sidebar-nav">
            <div class="container">
              <ul class="nav in side-menu justify-content-between">
                <li :class="{ active: active === 'home' }"><router-link to="/blog"><i class="list-icon fal fa-fw fa-home"></i>Blog Home</router-link></li>
                <li :class="{ active: active === 'mobile' }"><router-link to="/blog/tag/mobile"><i class="list-icon fal fa-fw fa-mobile"></i>Mobile</router-link></li>
                <li :class="{ active: active === 'performance' }"><router-link to="/blog/tag/performance"><i class="list-icon fal fa-fw fa-rocket-launch"></i>Performance</router-link></li>
                <li :class="{ active: active === 'security' }"><router-link to="/blog/tag/security"><i class="list-icon fal fa-fw fa-shield-alt"></i>Security</router-link></li>
                <li :class="{ active: active === 'ui-ux' }"><router-link to="/blog/tag/ui-ux"><i class="list-icon fal fa-fw fa-phone-laptop"></i>UI/UX</router-link></li>
              </ul>
            </div>
          </nav>
        </aside>
      </div>
    </div>
  </div>
</template>

<script>
import AccountHeader from '@/components/AccountHeader.vue';

export default {
  props: {
    nav: String,
    active: String,
  },
  data() {
    return {
      authenticated: undefined,
      showNav: false,
    };
  },
  methods: {
    toggleNav() {
      this.showNav = !this.showNav;
    },
  },
  async mounted() {
    this.authenticated = await this.$auth.isAuthenticated();
  },
  components: {
    AccountHeader,
  },
};
</script>

<style lang="scss" scoped>
.nav-item { font-weight: 600; align-items: center; display: flex; }
.navbar-nav { justify-content: flex-end; }
.navbar-nav > li > a { font-size: 0.875rem; color: #f8f9fa; display: inline-block; line-height: 1.5; }
</style>
