<template>
  <div v-if="$store.getters.ready" class="heading-font-family">
    <div class="expand-vertical">
      <Header nav="help" :active="this.$route.params.article" />
      <section class="container-fluid">
        <div class="container mt-3 pb-4">
          <h2>{{ article.attributes.title }}</h2>
          <div id="articlebody" v-html="article.body"></div>
        </div>
      </section>
    </div>
    <Footer class="mt-2" />
  </div>
</template>

<script>
import frontmatter from 'tiny-frontmatter';
import Header from '@/components/NavHeader.vue';
import Footer from '@/components/Footer.vue';

export default {
  data() {
    return {
      article: {},
    };
  },
  methods: {
    linkClick(e) {
      const dest = e.target.attributes.href.value;
      if (dest.startsWith('/') && !dest.startsWith('//') && !dest.startsWith('/contents')) {
        e.preventDefault();
        this.$router.push(dest);
      }
    },
  },
  async mounted() {
    try {
      const a = (await this.$cache.http.get(`/articles/${this.$route.params.section}/${this.$route.params.article}.json`, { headers: { Accept: 'text/html' } })).data;
      if (a instanceof Object && process.env.NODE_ENV === 'production') {
        this.article = a;
      } else {
        await import(/* webpackChunkName: "markdown" */ '@/modules/markdown').then(async (markdown) => {
          const md = markdown.default;
          md.config(this);
          const amd = (await this.$cache.http.get(`/articles/${this.$route.params.section}/${this.$route.params.article}.md`, { headers: { Accept: 'text/html' } })).data;
          if (!amd.startsWith('---\n')) {
            throw new Error(404);
          }
          this.article = frontmatter(amd);
          this.article.body = md.render(this.article.body);
        });
      }
      // set title
      document.title = `${this.article.attributes.title} - ${this.$config.pretty_name}`;
      this.$store.commit('ready', true);
    } catch (e) {
      this.$store.commit('error', e);
    }
  },
  updated() {
    // fake router-link so we dont have to include the vue compiler
    const links = document.querySelectorAll('#articlebody a');
    for (let i = 0; i < links.length; i += 1) {
      links[i].addEventListener('click', this.linkClick);
    }
  },
  beforeDestroy() {
    const links = document.querySelectorAll('#articlebody a');
    for (let i = 0; i < links.length; i += 1) {
      links[i].removeEventListener('click', this.linkClick);
    }
  },
  components: {
    Header,
    Footer,
  },
};
</script>
